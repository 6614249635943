<template>
	<el-dialog class="dialog" title="新增/编辑检修记录" :visible.sync="visiable" width="60%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px" :rules="rules">
				<el-row>
					<el-col :span="12">
						<el-form-item label="厂区：" prop="factory_id">
							<el-select v-model="formData.factory_id" collapse-tags clearable
								:popper-append-to-body="false" placeholder="请选择" @change="selectfactoryList"
								@clear="clearfactoryList" v-if="dialogObj.type==2">
								<el-option v-for="item in factoryList" :key="item.name" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
							<el-input v-model="formData.factory_name" disabled v-else></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="车间：" prop="workshop_id">
							<el-select v-model="formData.workshop_id" collapse-tags clearable
								:popper-append-to-body="false" placeholder="请选择" @change="selectworkshopList"
								@clear="clearworkshopList" v-if="dialogObj.type==2">
								<el-option v-for="item in workshopList" :key="item.name" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
							<el-input v-model="formData.workshop_name" disabled v-else></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="设备名称：" prop="unit_id">
							<el-select v-model="formData.unit_id" collapse-tags clearable :popper-append-to-body="false"
								placeholder="请选择" @change="selectunitList" v-if="dialogObj.type==2">
								<el-option v-for="item in unitList" :key="item.name" :label="item.name"
									:value="item.id">
								</el-option>
							</el-select>
							<el-input v-model="formData.unit_name" disabled v-else></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="检修时间：" prop="time">
							<el-date-picker v-model="formData.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
								:disabled="dialogObj.type==1" placeholder="请选择日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="检测类型：" prop="type">
							<el-radio-group v-model="formData.type" :disabled="dialogObj.type==1||!formData.unit_name">
								<el-radio :label="1">检查</el-radio>
								<el-radio :label="2">更换</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="责任人：" prop="duty_person">
							<el-input v-model="formData.duty_person"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formData.type==1">
					<el-col :span="12" style="padding:0 30px 20px;">
						<span>填写本次检测结果：</span>
					</el-col>
					<el-col :span="12" style="padding:0 30px 20px;">
						<span>上次检测结果（{{formData.preDetectTime}}）</span>
					</el-col>
				</el-row>
				<el-row v-if="formData.type==1">
					<el-col :span="12" style="padding:0 30px;">
						<el-table :data="formData.comps" border style="width: 100%" max-height="300">
							<el-table-column prop="comp_name" label="设备部件" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="status" label="运行状态" width="220">
								<template slot-scope="scope">
									<el-radio-group v-model="scope.row.status">
										<el-radio :label="1">良</el-radio>
										<el-radio :label="2">中</el-radio>
										<el-radio :label="3">差</el-radio>
									</el-radio-group>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :span="12" style="padding:0 30px;">
						<el-table :data="formData.comps" border style="width: 100%" max-height="300">
							<el-table-column prop="comp_name" label="设备部件" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="pre_status" label="运行状态" width="120">
								<template slot-scope="scope">
									<div v-if="scope.row.pre_status==1">良</div>
									<div v-if="scope.row.pre_status==2">中</div>
									<div v-if="scope.row.pre_status==3">差</div>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row v-if="formData.type==2">
					<el-col :span="12" style="padding:0 30px 20px;">
						<span>选择本次更换部件：</span>
					</el-col>
					<el-col :span="12" style="padding:0 30px 20px;">
						<span>上次更换时间（{{formData.preRepairTime}}）</span>
					</el-col>
				</el-row>
				<el-row v-if="formData.type==2">
					<el-col :span="12" style="padding:0 30px;">
						<el-table :data="formData.comps" border style="width: 100%" max-height="300">
							<el-table-column prop="comp_name" label="设备部件" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="status" label="是否更换" width="140">
								<template slot-scope="scope">
									<el-radio-group v-model="scope.row.status">
										<el-radio :label="0">是</el-radio>
										<el-radio :label="1">否</el-radio>
									</el-radio-group>
								</template>
							</el-table-column>
							<el-table-column prop="life_day" label="设备寿命(天)" width="110">
								<template slot-scope="scope">
									<el-input v-model="scope.row.life_day"></el-input>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :span="12" style="padding:0 30px;">
						<el-table :data="formData.comps" border style="width: 100%" max-height="300">
							<el-table-column prop="comp_name" label="设备部件" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="pre_time" label="上次更换时间" width="115" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="use_day" label="使用时长(天)" width="110">
							</el-table-column>
							<el-table-column prop="pre_life_day" label="建议使用寿命(天)" width="140">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row style="margin-top: 20px;">
					<el-col :span="24">
						<el-form-item label="检测说明：">
							<el-input type="textarea" v-model="formData.cause"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
				<el-button type="primary" @click="submit()">确定</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
				factoryList: [],
				workshopList: [],
				unitList: [],
				title: "",
				loading: false,
				formData: {},
				newFile: new FormData(),
				headUrl: "",
				roles: [],
				departments: [],
				rules: {
					factory_id: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					workshop_id: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					unit_id: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					time: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					type: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
					duty_person: {
						required: true,
						message: "此项不能为空",
						trigger: 'blur'
					},
				}
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.factoryList = [];
				this.workshopList = [];
				this.unitList = [];
				this.$nextTick(() => {
					this.$refs.form.clearValidate();
				})
				if (this.dialogObj.type == 1) {
					this.get();
				}
				this.getArea({
					type: 0
				});
			},
			getArea(obj) {
				this.$get("/backend-api/eqp/set/sel", obj).then((res) => {
					if (res.code == 1000) {
						if (obj.type == 0) {
							this.factoryList = res.data
						} else if (obj.type == 10) {
							this.workshopList = res.data
						} else if (obj.type == 100) {
							this.unitList = res.data
						}
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			selectfactoryList(id) {
				if (id) {
					this.$forceUpdate();
					this.clearfactoryList();
					this.formData.factory_name = "";
					for (let item of this.factoryList) {
						if (item.id == id) {
							this.formData.factory_name = item.name;
						}
					}
					this.getArea({
						id: id,
						type: 10
					});
				}
			},
			selectworkshopList(id) {
				if (id) {
					this.$forceUpdate();
					this.clearworkshopList();
					this.formData.workshop_name = "";
					for (let item of this.workshopList) {
						if (item.id == id) {
							this.formData.workshop_name = item.name;
						}
					}
					this.getArea({
						id: id,
						type: 100
					});
				}
			},
			selectunitList(id) {
				if (id) {
					this.$forceUpdate();
					for (let item of this.unitList) {
						if (item.id == id) {
							this.formData.unit_name = item.name;
							this.$get("/backend-api/data/detect-repair/v2/comp-list", {
								typeId: item.type_id,
								unitId: item.id
							}).then((res) => {
								if (res.code == 1000) {
									this.$set(this.formData, "comps", res.data.comps);
									this.$set(this.formData, "preDetectTime", res.data.preDetectTime);
									this.$set(this.formData, "preRepairTime", res.data.preRepairTime);
								} else {
									this.$message.error(res.msg);
								}
							});
							return;
						}
					}
				}
			},
			clearfactoryList() {
				this.workshopList = [];
				this.unitList = [];
				this.formData.workshop_id = "";
				this.formData.workshop_name = "";
				this.formData.unit_id = "";
				this.formData.unit_name = "";
			},
			clearworkshopList() {
				this.unitList = [];
				this.formData.unit_id = "";
				this.formData.unit_name = "";
			},
			//获取数据
			get() {
				this.loading = true;
				this.$get("/backend-api/data/detect-repair/get", {
					id: this.dialogObj.id
				}).then((res) => {
					if (res.code == 1000) {
						this.formData = res.data;
						this.loading = false;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			submit() {
				if (this.dialogObj.type == 1) {
					this.submitUpdate()
				} else if (this.dialogObj.type == 2) {
					this.submitInsert()
				}
			},
			submitInsert() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData("/backend-api/data/detect-repair/save-or-update", this.formData).then((
							res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				});
			},
			submitUpdate() {
				this.$refs["form"].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$postData("/backend-api/data/detect-repair/save-or-update", this.formData).then((
							res) => {
							if (res.code == 1000) {
								this.loading = false;
								this.$message.success(res.msg);
								this.$parent.list();
								this.close();
							} else {
								this.$message.error(res.msg);
							}
						});
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.el-dialog {
			min-width: 900px;

		}

		::v-deep.cascader {
			display: block;
		}

		::v-deep.el-image {
			width: 80px;
			height: 80px;
			margin-right: 20px;
		}

		.images {
			margin: 10px 25px;
		}
	}

	.avatar-uploader {
		display: inline-block;
		margin-right: 20px;

		::v-deep .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 80px;
			height: 80px;
		}

		::v-deep .el-upload:hover {
			border-color: #409EFF;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
</style>
