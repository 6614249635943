<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="6">
						<el-form-item label="厂区">
							<el-select
								v-model="searchParm.factory_id"
								collapse-tags
								clearable
								:popper-append-to-body="false"
								placeholder="请选择"
								@change="selectfactory"
								@clear="clearfactoryList"
							>
								<el-option v-for="item in factoryList" :key="item.name" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="车间">
							<el-select
								v-model="searchParm.workshop_id"
								collapse-tags
								clearable
								:popper-append-to-body="false"
								placeholder="请选择"
								@change="selectworkshop"
								@clear="clearworkshopList"
							>
								<el-option v-for="item in workshopList" :key="item.name" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="设备名称">
							<el-select v-model="searchParm.unit_id" collapse-tags clearable :popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in unitList" :key="item.name" :label="item.name" :value="item.id"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item label="检修类型">
							<el-select v-model="searchParm.type" collapse-tags clearable :popper-append-to-body="false" placeholder="请选择" @change="$forceUpdate()">
								<el-option v-for="item in typeList" :key="item.name" :label="item.name" :value="item.num"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-button type="primary" size="medium" @click="insert()" style="margin-right: 8px;">新增记录</el-button>
					<el-button type="primary" size="medium" @click="download()" style="margin-right: 8px;">下载excel</el-button>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table
							ref="usertable"
							:data="tableData.records"
							border
							:height="tableHeight"
							:header-cell-style="{ 'background-color': '#f9fafc' }"
							:cell-style="{ padding: 0 }"
							style="margin-bottom: 20px;"
							v-loading="isLoading"
						>
							<el-table-column align="center" label="序号" show-overflow-tooltip>
								<template slot-scope="scope">
									<div>{{ scope.$index + 1 }}</div>
								</template>
							</el-table-column>
							<el-table-column prop="unit_name" align="center" label="设备名称" show-overflow-tooltip></el-table-column>
							<el-table-column prop="type" align="center" label="检修类型" show-overflow-tooltip>
								<template slot-scope="scope">
									<div v-if="scope.row.type == 1">检查</div>
									<div v-if="scope.row.type == 2">更换</div>
								</template>
							</el-table-column>
							<el-table-column prop="duty_person" align="center" label="责任人" show-overflow-tooltip></el-table-column>
							<el-table-column prop="time" align="center" label="检修时间" show-overflow-tooltip></el-table-column>
							<el-table-column prop="cause" align="center" label="检测说明" show-overflow-tooltip></el-table-column>
							<el-table-column fixed="right" align="center" label="操作" width="160">
								<template slot-scope="scope">
									<el-button type="text" @click="look(scope.row)">查看</el-button>
									<el-button type="text" @click="edit(scope.row)">编辑</el-button>
									<el-button type="text" @click="remove(scope.row)">删除</el-button>
								</template>
							</el-table-column>
							<template slot="empty">
								<el-empty :image-size="100" description="暂无数据"></el-empty>
							</template>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						style="float: right;"
						@current-change="handleCurrentChange"
						:current-page.sync="searchParm.current"
						:page-size="searchParm.size"
						layout="total, prev, pager, next,jumper"
						:total="tableData.total"
					></el-pagination>
				</div>
			</div>
		</div>

		<edit :isShow.sync="showEditDialog" :dialogObj="editObj"></edit>
		<look :isShow.sync="showLookDialog" :dialogObj="lookObj"></look>
	</div>
</template>
<script>
import smCard from '@c/smCard.vue';
import edit from './edit.vue';
import look from './look.vue';
import caches from "@cache/cache.js"
export default {
	components: {
		smCard,
		edit,
		look
	},
	data() {
		return {
			tableHeight: 0,
			tableData: {
				records: []
			},
			searchParm: {
				size: 20,
				current: 1
			},
			// batchActionType: '',
			showEditDialog: false,
			showLookDialog: false,
			editObj: {},
			lookObj: {},
			factoryList: [],
			workshopList: [],
			unitList: [],
			typeList: [{ num: 1, name: '检查' }, { num: 2, name: '更换' }],
			isLoading: false
		};
	},
	methods: {
		getArea(obj) {
			this.$get('/backend-api/eqp/set/sel', obj).then(res => {
				if (res.code == 1000) {
					if (obj.type == 0) {
						this.factoryList = res.data;
					} else if (obj.type == 10) {
						this.workshopList = res.data;
					} else if (obj.type == 100) {
						this.unitList = res.data;
					}
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		selectfactory(id) {
			if (id) {
				this.clearfactoryList();
				this.getArea({
					id: id,
					type: 10
				});
			}
		},
		selectworkshop(id) {
			if (id) {
				this.clearworkshopList();
				this.getArea({
					id: id,
					type: 100
				});
			}
		},
		clearfactoryList() {
			this.workshopList = [];
			this.unitList = [];
			this.searchParm.workshop_id = '';
			this.searchParm.workshop_name = '';
			this.searchParm.unit_id = '';
			this.searchParm.unit_name = '';
		},
		clearworkshopList() {
			this.unitList = [];
			this.searchParm.unit_id = '';
			this.searchParm.unit_name = '';
		},
		//自适应表格高度
		calHeight() {
			this.$nextTick(() => {
				const rect = this.$refs.tableContainer.getBoundingClientRect();
				this.tableHeight = rect.height;
			});
		},
		//清空搜索
		clearSearch() {
			this.searchParm = {
				size: 20,
				current: 1
			};
		},
		//展示数据
		list() {
			//请求之前，开启loading
			this.isLoading = true;
			this.$get('/backend-api/data/detect-repair/list', this.searchParm)
				.then(res => {
					this.isLoading = false;
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch(err => {
					//请求失败，关闭loading
					this.isLoading = false;
				});
		},
		//打开查看弹窗
		look(row) {
			this.lookObj = {};
			this.lookObj['id'] = row.id;
			this.showLookDialog = true;
		},
		//打开新增弹窗
		insert() {
			this.editObj = {};
			this.editObj['type'] = 2;
			this.showEditDialog = true;
		},
		//打开编辑弹窗
		edit(row) {
			this.editObj = {};
			this.editObj['id'] = row.id;
			this.editObj['type'] = 1;
			this.showEditDialog = true;
		},
		//分页选择页面回调
		handleCurrentChange(val) {
			this.searchParm.current = val;
			this.list();
		},
		//删除
		remove(row) {
			this.$confirm('是否确认删除？', '删除子账号', {
				dangerouslyUseHTMLString: true,
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.$post('/backend-api/data/detect-repair/delete', {
						id: row.id
					}).then(res => {
						if (res.code == 1000) {
							this.$message.success(res.msg);
							//刷新表格
							this.list();
						} else {
							this.$message.error(res.msg);
						}
					});
				})
				.catch(() => {});
		},
		download() {
			let url = this.baseURL + '/backend-api/data/detect-repair/download?ent_id='+caches.user.get().entId;
			if(this.searchParm.factory_id){
				url+='&factory_id='+this.searchParm.factory_id
			}
			if(this.searchParm.type){
				url+='&type='+this.searchParm.type
			}
			if(this.searchParm.unit_id){
				url+='&unit_id='+this.searchParm.unit_id
			}
			if(this.searchParm.workshop_id){
				url+='&workshop_id='+this.searchParm.workshop_id
			}
			// //访问路径直接下载
			window.location.href = url;
		}
	},
	mounted() {
		this.list();
		this.getArea({
			type: 0
		});
		this.calHeight();
		window.addEventListener('resize', this.calHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.calHeight);
	}
};
</script>
<style lang="scss" scoped>
@import '@style/userCenter.scss';
</style>
