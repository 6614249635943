<template>
	<el-dialog class="dialog" title="新增/编辑检修记录" :visible.sync="visiable" width="60%">
		<div v-loading="loading" class="dlalog-body">
			<el-form ref="form" :model="formData" label-width="120px">
				<el-row>
					<el-col :span="12">
						<el-form-item label="厂区：" prop="factory_id">
							<el-input v-model="formData.factory_name" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="车间：" prop="workshop_id">
							<el-input v-model="formData.workshop_name" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="设备名称：" prop="unit_id">
							<el-input v-model="formData.unit_name" disabled></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="检修时间：">
							<el-date-picker v-model="formData.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
								placeholder="请选择日期" disabled>
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="12">
						<el-form-item label="检测类型：">
							<el-radio-group v-model="formData.type" disabled>
								<el-radio :label="1">检查</el-radio>
								<el-radio :label="2">更换</el-radio>
							</el-radio-group>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="责任人：">
							<el-input v-model="formData.duty_person" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row v-if="formData.type==1">
					<el-col :span="12" style="padding:0 30px 20px;">
						<span>填写本次检测结果：</span>
					</el-col>
					<el-col :span="12" style="padding:0 30px 20px;">
						<span>上次检测结果（{{formData.pre_time}}）</span>
					</el-col>
				</el-row>
				<el-row v-if="formData.type==1">
					<el-col :span="12" style="padding:0 30px;">
						<el-table :data="formData.comps" border style="width: 100%" max-height="300">
							<el-table-column prop="comp_name" label="设备部件" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="status" label="运行状态" width="220">
								<template slot-scope="scope">
									<div v-if="scope.row.status==1">良</div>
									<div v-if="scope.row.status==2">中</div>
									<div v-if="scope.row.status==3">差</div>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :span="12" style="padding:0 30px;">
						<el-table :data="formData.comps" border style="width: 100%" max-height="300">
							<el-table-column prop="comp_name" label="设备部件" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="pre_status" label="运行状态" width="120">
								<template slot-scope="scope">
									<div v-if="scope.row.pre_status==1">良</div>
									<div v-if="scope.row.pre_status==2">中</div>
									<div v-if="scope.row.pre_status==3">差</div>
								</template>
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row v-if="formData.type==2">
					<el-col :span="12" style="padding:0 30px 20px;">
						<span>选择本次更换部件：</span>
					</el-col>
					<el-col :span="12" style="padding:0 30px 20px;">
						<span>上次更换时间（{{formData.pre_time}}）</span>
					</el-col>
				</el-row>
				<el-row v-if="formData.type==2">
					<el-col :span="12" style="padding:0 30px;">
						<el-table :data="formData.comps" border style="width: 100%" max-height="300">
							<el-table-column prop="comp_name" label="设备部件" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="status" label="是否更换" width="140">
								<template slot-scope="scope">
									<div v-if="scope.row.status==0">是</div>
									<div v-if="scope.row.status==1">否</div>
								</template>
							</el-table-column>
							<el-table-column prop="life_day" label="设备寿命(天)" width="110">
							</el-table-column>
						</el-table>
					</el-col>
					<el-col :span="12" style="padding:0 30px;">
						<el-table :data="formData.comps" border style="width: 100%" max-height="300">
							<el-table-column prop="comp_name" label="设备部件" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="pre_time" label="上次更换时间" width="115" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="use_day" label="使用时长(天)" width="110">
							</el-table-column>
							<el-table-column prop="pre_life_day" label="建议使用寿命(天)" width="140">
							</el-table-column>
						</el-table>
					</el-col>
				</el-row>
				<el-row style="margin-top: 20px;">
					<el-col :span="24">
						<el-form-item label="检测说明：">
							<el-input type="textarea" v-model="formData.cause" disabled></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close()">关闭</el-button>
			</div>
		</div>
	</el-dialog>
</template>
<script>
	import {
		dialogMixin
	} from "@m/dialogMixin"
	import configApi from '@config/configApi'
	export default {
		mixins: [dialogMixin],
		data() {
			return {
				title: "",
				loading: false,
				formData: {},
			};
		},
		props: {},
		watch: {},
		methods: {
			//打开弹窗
			open() {
				this.formData = {};
				this.get();
			},
			//获取数据
			get() {
				this.loading = true;
				this.$get("/backend-api/data/detect-repair/get", {
					id: this.dialogObj.id
				}).then((res) => {
					if (res.code == 1000) {
						this.formData = res.data;
						this.loading = false;
					} else {
						this.$message.error(res.msg);
						this.loading = false;
					}
				});
			},
			//关闭弹窗
			close() {
				this.visiable = false
			}
		}
	};
</script>
<style lang='scss' scoped>
	.dialog {
		::v-deep.el-dialog {
			min-width: 900px;

		}

		::v-deep.cascader {
			display: block;
		}

		::v-deep.el-image {
			width: 80px;
			height: 80px;
			margin-right: 20px;
		}

		.images {
			margin: 10px 25px;
		}
	}

	.avatar-uploader {
		display: inline-block;
		margin-right: 20px;

		::v-deep .el-upload {
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			width: 80px;
			height: 80px;
		}

		::v-deep .el-upload:hover {
			border-color: #409EFF;
		}
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 80px;
		height: 80px;
		line-height: 80px;
		text-align: center;
	}
</style>
